import { useI18n } from 'vue-i18n';
import Toast from '@/components/Toast/index';
import Backend from '@/core/Services/Backend';
import { reactive, onMounted, toRefs, computed, watch, } from 'vue';
import { debounce } from 'lodash-es';
import useHistory from '@/utils/useHistory';
import StorageServices from '@/core/Services/StorageServices';
/**
 * 编辑批量空投nft页的链/币种选项hook
 * @returns
 */
const useNfts = () => {
    const { t } = useI18n();
    const { add: addHistory, get: getHistory } = useHistory('nftHistory');
    const state = reactive({
        chainTypeOptions: [],
        selectedChainType: '',
        selectedNft: '',
        selectedNftStandard: '721',
    });
    const tokenOptions = computed(() => {
        const target = state.chainTypeOptions.find((item) => item.value === state.selectedChainType);
        const history = getHistory(state.selectedChainType);
        if (!target || !history) {
            return [];
        }
        // return [...new Set([...history, target.token])];
        return history;
    });
    // 切换链的时候清空token选择
    watch(() => state.selectedChainType, () => {
        state.selectedNft = '';
        state.selectedNftStandard = '721';
    });
    // 获取nft合约标准
    const getNftStandard = async () => {
        state.selectedNftStandard = '';
        if (!state.selectedNft) {
            return;
        }
        const { result } = await Backend.getContractStandard(state.selectedChainType, state.selectedNft);
        if (!result) {
            Toast.error(t('contract_not_found'));
            return;
        }
        state.selectedNftStandard = String(result);
        addHistory(state.selectedChainType, state.selectedNft);
    };
    const debouncedGetTokenDecimal = debounce(getNftStandard, 1000);
    // 查询合约标准
    watch(() => state.selectedNft, () => {
        debouncedGetTokenDecimal();
    });
    onMounted(async () => {
        const chains = await Backend.getChains();
        const userChains = StorageServices.getUserChains();
        state.chainTypeOptions = chains.filter((item) => userChains.includes(item.chainType)).map((item) => ({
            label: item.chain,
            value: item.chainType,
            token: item.symbol,
        }));
        state.selectedChainType = state.chainTypeOptions[0].value;
    });
    return {
        ...toRefs(state),
        tokenOptions,
    };
};
export default useNfts;
