import MultiDrop from '@/core/Interactors/MultiDrop';
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch, } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import ButtonLayer from '@/components/ButtonLayer.vue';
// import { crypto } from '@okexchain/javascript-sdk';
import Toast from '@/components/Toast';
import Backend from '@/core/Services/Backend';
import { debounce, cloneDeep } from 'lodash-es';
import { duplicateChecking, deleteDuplicate } from '@/utils/arrayOfObjects';
export default defineComponent({
    name: 'AddressTable',
    components: {
        BaseButton,
        ButtonLayer,
    },
    props: {
        addressList: {
            type: Array,
            required: true,
        },
        chain: {
            type: String,
            required: true,
        },
        standard: {
            type: String,
            required: true,
        },
        tokenHash: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const state = reactive({
            list: props.addressList.map((item) => ({
                stamp: String(Math.random()),
                address: item.address,
                tokenId: item.tokenId,
                count: item.count,
                isError: MultiDrop.getNftError(props.standard, item.address, item.tokenId, item.count),
            })),
        });
        // 地址格式错误的列表
        const errorList = computed(() => state.list
            .map((item, index) => ({
            ...item,
            index,
        }))
            .filter((item) => item.isError));
        const delErrorList = () => {
            for (let i = 0; i <= state.list.length - 1; i += 1) {
                if (state.list[i].isError) {
                    state.list.splice(i, 1);
                    i -= 1;
                }
            }
        };
        // tokenId查重的列表
        const tokenIdDuplicateList = computed(() => duplicateChecking(state.list, 'tokenId'));
        // 删除重复tokenId的nft
        const deletetokenIdDuplicate = () => {
            state.list = deleteDuplicate(state.list, 'tokenId');
        };
        // 地址查重的列表
        const addressDuplicateList = computed(() => duplicateChecking(state.list, 'address'));
        // 是否保留地址重复项
        const isKeepAddressDuplicate = ref(false);
        const keepAddressDuplicate = () => {
            isKeepAddressDuplicate.value = true;
        };
        // 删除重复地址的nft
        const deleteAddressDuplicate = () => {
            state.list = deleteDuplicate(state.list, 'address');
        };
        const deleteLine = (index) => {
            state.list.splice(index, 1);
        };
        const addLine = () => {
            state.list.push({
                stamp: String(Math.random()),
                address: '',
                tokenId: '',
                count: '',
                isError: true,
            });
        };
        const checkAddress = (index) => {
            const target = state.list[index];
            target.isError = MultiDrop.getNftError(props.standard, target.address, target.tokenId, target.count);
        };
        // 是否已经删除不属于当前钱包的nft
        const deletedInvalid = ref(false);
        const notOwnedList = ref([]);
        // 校验是否拥有nft
        const verifyOwnership = async () => {
            const res = await Backend.verifyOwnership(props.chain, props.tokenHash, props.standard, state.list.map((item) => ({
                tokenId: item.tokenId,
                value: item.count && item.count,
            })));
            notOwnedList.value = res.tokens.filter((item) => !item.isOwnership);
            if (notOwnedList.value.length === 0) {
                deletedInvalid.value = true;
            }
        };
        const delNotOwnedList = () => {
            const tempList = state.list;
            notOwnedList.value.forEach((item) => {
                tempList[Number(item.id)] = null;
            });
            notOwnedList.value = [];
            state.list = tempList.filter((item) => item !== null);
            deletedInvalid.value = true;
        };
        const step = computed(() => {
            if (errorList.value.length) {
                return 1;
            }
            // 1155标准的允许重复，不走去重流程
            if (tokenIdDuplicateList.value.length && props.standard === '721') {
                return 2;
            }
            if (addressDuplicateList.value.length && !isKeepAddressDuplicate.value) {
                return 3;
            }
            if (!deletedInvalid.value) {
                return 4;
            }
            return 5;
        });
        // 防抖
        const debounceVerifyOwnership = debounce(verifyOwnership, 1000);
        watch(state.list, () => {
            // 手动修改列表时状态初始化
            isKeepAddressDuplicate.value = false;
            deletedInvalid.value = false;
            if (step.value === 4) {
                debounceVerifyOwnership();
            }
        });
        watch(step, () => {
            if (step.value === 4) {
                debounceVerifyOwnership();
            }
        });
        onMounted(() => {
            if (step.value === 4) {
                debounceVerifyOwnership();
            }
        });
        // 合并相同的tokenid，数量相加
        const packedArray = (oldArray) => {
            const newArray = cloneDeep(oldArray);
            for (let i = 0; i <= newArray.length - 1; i += 1) {
                for (let j = i + 1; j < newArray.length; j += 1) {
                    if (newArray[i].tokenId === newArray[j].tokenId) {
                        newArray[i].count = String(Number(newArray[j].count) + Number(newArray[i].count));
                        newArray.splice(j, 1);
                        j -= 1;
                    }
                }
            }
            return newArray;
        };
        const nextStep = async () => {
            if (!(props.chain && props.tokenHash && props.standard && state.list.length)) {
                Toast.error(t('drop_form_invalid'));
                return;
            }
            const packedList = packedArray(state.list);
            const res = await Backend.verifyOwnership(props.chain, props.tokenHash, props.standard, packedList.map((item) => ({
                tokenId: item.tokenId,
                value: item.count && item.count,
            })));
            const notOwnershipList = res.tokens.filter((item) => !item.isOwnership);
            const notOwnershipTokenid = notOwnershipList.map((item) => item.tokenId);
            if (notOwnershipList.length > 0) {
                Toast.error(`${t('nft_excess')};Token ID:${notOwnershipTokenid.join(',')}`);
                return;
            }
            sessionStorage.setItem('nftSet', JSON.stringify({
                addressList: state.list,
                chain: props.chain,
                standard: props.standard,
                tokenHash: props.tokenHash,
            }));
            router.push('/inc/nft/gas');
        };
        return {
            t,
            ...toRefs(state),
            deleteLine,
            addLine,
            errorList,
            delErrorList,
            addressDuplicateList,
            isKeepAddressDuplicate,
            keepAddressDuplicate,
            checkAddress,
            deleteAddressDuplicate,
            nextStep,
            // Bech32List,
            step,
            // Bech32ToHex,
            delNotOwnedList,
            notOwnedList,
            tokenIdDuplicateList,
            // keepTokenIdDuplicate,
            deletetokenIdDuplicate,
        };
    },
});
