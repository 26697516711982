import { computed, defineComponent, reactive, toRefs, } from 'vue';
import BaseSelect from '@/components/BaseSelect.vue';
import SelectInput from '@/components/SelectInput.vue';
// import InputField from '@/components/InputField.vue';
import UploadFile from '@/components/UploadFile.vue';
import { useI18n } from 'vue-i18n';
import StepContainer from '@/components/StepContainer.vue';
import ButtonLayer from '@/components/ButtonLayer.vue';
import ContractAddressVue from '@/components/ContractAddress.vue';
import AddressTable from './AddressTable.vue';
import FileExample from './FileExample.vue';
import useNfts from './useNfts';
export default defineComponent({
    name: 'DropForm',
    components: {
        BaseSelect,
        SelectInput,
        // InputField,
        AddressTable,
        UploadFile,
        FileExample,
        StepContainer,
        ButtonLayer,
        ContractAddressVue,
    },
    setup() {
        const { t } = useI18n();
        const steps = computed(() => [
            {
                title: t('nft_step1'),
                content: t('nft_step1_content'),
            },
            {
                title: t('nft_step2'),
                content: t('nft_step2_content'),
            },
            {
                title: t('nft_step3'),
                content: t('nft_step3_content'),
            },
        ]);
        const { selectedChainType, chainTypeOptions, selectedNft, tokenOptions, selectedNftStandard, } = useNfts();
        const state = reactive({
            addressList: [],
            isManual: false,
        });
        const addressImportHandler = (addressList) => {
            state.addressList = addressList;
            state.isManual = true;
        };
        const toUpload = () => {
            state.addressList = [];
            state.isManual = false;
        };
        const toManual = () => {
            state.addressList = [{
                    address: '',
                    tokenId: '',
                    count: '',
                }];
            state.isManual = true;
        };
        return {
            t,
            ...toRefs(state),
            selectedChainType,
            chainTypeOptions,
            selectedNft,
            tokenOptions,
            selectedNftStandard,
            addressImportHandler,
            steps,
            toUpload,
            toManual,
        };
    },
});
